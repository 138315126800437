import React from "react"
import VideoPage from "../../components/VideoPage"
// import PropTypes from 'prop-types'
// import { Link } from 'gatsby' 
// import { connect } from 'react-redux'
// import {Spinner} from 'reactstrap'

// import { fetchVideo, fetchRelatedVideos } from '../../api'

// import AdComponent from '../../components/AdComponent'
// import Layout from '../../components/Layout'
// import Metadata from '../../components/Metadata'
// import RelatedMovies from '../../components/RelatedMovies'
// import Video from '../../components/Video'

// // import "../../templates/movie.css"

// // import History from '../../components/History'
// import { toast } from "react-toastify"
// // import { parseUrlParams } from "../utils"
// import { LogPageView } from "../../components/LogEvents"
// import { Disqus } from "gatsby-plugin-disqus"
// import Navbar from "reactstrap/lib/Navbar"
// import Nav from "reactstrap/lib/Nav"
// import NavItem from "reactstrap/lib/NavItem"


// class VideoPage extends React.Component {
//   static propTypes = {
//     user: PropTypes.object,
//     token: PropTypes.string,
//   }

//   constructor(props) {
//     super(props)
//     this.state = {
//       videoId:null,
//       video: null,
//       movieTranslation: null,
//       related: null,
//       recommendationNumber: 10,
//     }
//   }

//   componentWillMount() {
//     if (typeof window !== "undefined") {
//       require("intersection-observer")
//     }
//   }

//   componentDidMount(){
//     this.updatePage()
//   }

//   async componentDidUpdate(prevProps){
//     if (this.props.location !== prevProps.location) {
//       this.updatePage()
//     }
//   }

//   async updatePage() {
//     this.setState({
//       video: null,
//       movieTranslation: null,
//       related: null,
//     })
//     // const {id} = parseUrlParams(this.props.search)
//     const id = this.props.videoId
//     this.setState({videoId: id})
//     await this.getVideo(id)
//     await this.getRelated(id)
//   }

//   async getVideo(id) {
//     try {
//       const response = await fetchVideo(id)
//       this.setState({video: response.video, movieTranslation: response.movie_translation[0] })
//     } catch (err) {
//       toast.error("Impossible de charger la vidéo")
//     }
//   }

//   async getRelated(id){
//     try {
//       const response = await fetchRelatedVideos(id)
//       this.setState({related: response.results})
//     } catch (err) {
//       toast.error("Impossible de charger les recommandations")
//     }
//   }


//   handleNextRecommendationsLoad = async (event, isIntersecting) => {
//     const { recommendationNumber } = this.state
//     const maxRecommendations = this.props.data.internalMovies.recommendations.length
//     if (isIntersecting && recommendationNumber < maxRecommendations && !this.state.isRecommendationLoading && event.intersectionRatio === 1) {
//       await this.setState({
//         isRecommendationLoading: true,
//         recommendationNumber: recommendationNumber + 10, 
//       })
//       await this.setState({
//         isRecommendationLoading: false
//       })
//     }
//   }

  
//   render(){

//     const { videoId, video, movieTranslation, related } = this.state

//     if (!video) return <Placeholder/>

//     // const {user, token} = this.props

//     // const allVideos = videos ? <Video videos={videos}/> : ''

//     const allVideos = <Video videos={video} />
    
//     let title = ""

//     if (video[0].title) {
//       title = video[0].title
//     } else if (video[0].type === "trailer") {
//       title = `Bande annonce ${movieTranslation.title}`
//     } else {
//       title = `${movieTranslation.title} - (Film)`
//     }

//     return (
//       <div>
//         <Metadata
//           type="movie"
//           pathname={this.props.location.href}
//           title={title}
//           description={movieTranslation.synopsis}
//           poster={null}
//         />
//         <Navbar dark expand="lg" className="navigation">
//           <Nav navbar>
//             <NavItem>
//               <Link className="nav-link" to={`/t/${movieTranslation.slug}`}>
//                 ← {movieTranslation.title}
//               </Link>
//             </NavItem>
//           </Nav>
//         </Navbar>
//         <div className="container-fluid movie-page-container watch-container">

//           <div className="row">
//             <div className="col-lg-8 col-xs-12 p-0 m-0 pt-lg-3 pl-lg-3 pr-lg-0" id="video_et_description">
//               { video[0].taken_down 
//                 ? <div className="videoDescription">
//                     <h1 className="py-2 mb-0 pt-3 font-weight-bold" id="video-title">Cette vidéo a été supprimée</h1>
//                     <h5 className="font-weight-bold pb-4">Vidéos similaires 
//                       <svg className="d-none d-lg-flex" xmlns="http://www.w3.org/2000/svg" height="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14 5l7 7m0 0l-7 7m7-7H3" />
//                       </svg>
//                       <svg className="d-flex d-lg-none" xmlns="http://www.w3.org/2000/svg" height="15" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                         <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 17l-4 4m0 0l-4-4m4 4V3" />
//                       </svg>
//                     </h5>
//                   </div>
//                 : allVideos
//               }
//               { !video[0].taken_down && 
//                 <>
//                   <div className="videoDescription px-4 text-left">
//                     <h1 className="py-2 mb-0 pt-3" id="video-title">
//                       {title}
//                     </h1>
//                   </div>
      
//                   <div className="videoDescription px-4 pb-2 mb-3 text-left font-weight-light">
//                     <h1>Synopsis</h1>
//                     <p className="text-left"> 
//                       {movieTranslation.synopsis}
//                     </p>
//                     <Link to={`/t/${movieTranslation.slug}`}>
//                       <button className="btn btn-secondary btn-sm">Voir la fiche</button>
//                     </Link>
//                   </div>
//                   <div className="col-12 mx-auto py-3 px-md-5 px-sm-1 mb-5">
//                     <Disqus config={{ url: `https://cinetimes.org/${this.props.location.pathname}`, identifier: videoId, title: title}} />
//                   </div>
//                 </>
//               }

              
//               {/* Before description */}
//               <AdComponent client="ca-pub-3093835180445554" slot="1463045123" horizontal/> 

  
//             </div>


//             <RelatedMovies taken_down={video[0].taken_down} related={related} customClassName="col-lg-4 col-xs-12"/>

//             {/* <Observer onChange={this.handleNextRecommendationsLoad} rootMargin="0% 0% 25%">
//               <div className="text-center w-100">{this.state.isRecommendationLoading && <Spinner className="m-auto" color="primary" />}</div>
//             </Observer> */}

            
//             {/* <div className="col-lg-8 col-12 mx-0">
//               <Rows title="Les visiteurs ont aussi regardé" size="sm" movies={smart_recommendations}/>
//             </div> */}
              
//           </div>
//         </div>
//                 {/* <AmazonAd/> */}
  
//         {/* <History poster={poster} slug={slug} title={title}/> */}
//         <LogPageView event="pageview" slug={this.props.location.pathname + this.props.location.search} itemName="VideoLink" value={null} itemId={videoId} />
//       </div>
//     )
//   }
// }

// const mapStateToProps = state => ({
//   user: state.user.user,
//   token: state.user.token,
// })

// export default connect(mapStateToProps)(VideoPage)


// const Placeholder = () => {
//   return (
//     <div>
//       <div className="container-fluid movie-page-container watch-container">

//         <div className="row">
//           <div className="col-lg-8 col-xs-12 p-0 m-0 pt-lg-3 pl-lg-3 pr-lg-0" id="video_et_description">
//             <div className="tab-content mb-3" id="nav-tabContent">
//               <div className="tab-pane fade show active bg-white mb-2  m-auto text-center" id="nav-archive" role="tabpanel" aria-labelledby="nav-archive-tab">
//                 {/* <Spinner size="sm" className="m-auto text-center"/> */}
//               </div>
//             </div> 
//             {/* <div className="videoDescription px-4 text-left">
//               <h1 className="py-2 mb-0 pt-3" id="video-title">Loading</h1>
//             </div> */}

//             <div className="videoDescription m-auto text-center px-4 pb-2 mb-3 text-left font-weight-light">
//               <p className="py-5 m-0">
//                 <Spinner size="sm"/>
//               </p>
//             </div>

            
//             {/* Before description */}
//             <AdComponent client="ca-pub-3093835180445554" slot="1463045123" horizontal/> 


//           </div>

//           <div className="col-lg-4 col-xs-12 mx-auto mt-3 text-center">
//             <Spinner size="sm"/>
//           </div>
//           {/* <RelatedMovies related={[]} customClassName="col-lg-4 col-xs-12"/> */}
//           {/* <Observer onChange={this.handleNextRecommendationsLoad} rootMargin="0% 0% 25%">
//             <div className="text-center w-100">{this.state.isRecommendationLoading && <Spinner className="m-auto" color="primary" />}</div>
//           </Observer> */}

          
//           {/* <div className="col-lg-8 col-12 mx-0">
//             <Rows title="Les visiteurs ont aussi regardé" size="sm" movies={null}/>
//           </div> */}
            
//         </div>
//       </div>
//               {/* <AmazonAd/> */}

//       {/* <History poster={poster} slug={slug} title={title}/> */}
//     </div>
//   )
// }

const VideoLive = props => {
  return (
    <VideoPage videoId={props.params.videoId} static={false} search={props.search} location={props.location} />
  )
}

export default VideoLive